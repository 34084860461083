
















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

export default Vue.extend({
});
